import { createContext, useReducer } from "react"

export const WorkoutsContext = createContext()

// previous state argument with changes
// keep local changes in sync with database
export const workoutsReducer = (state, action) => {
    switch (action.type) {
        case "SET_WORKOUTS":
            return { workouts: action.payload }
        case "CREATE_WORKOUT":
            return { workouts: [action.payload, ...state.workouts] }
        case "DELETE_WORKOUT":
            return { workouts: state.workouts.filter((workout) => workout._id !== action.payload._id) }
        default:
            return state
    }
}

export const WorkoutsContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(workoutsReducer, {
        workouts: null
    })

    return (
        // spread operator
        <WorkoutsContext.Provider value={{...state, dispatch}}>
            { children }
        </WorkoutsContext.Provider>
    )
}